// extracted by mini-css-extract-plugin
export var arrow = "about-gallery-module--arrow--b7d51";
export var arrowleft = "about-gallery-module--arrowleft--9f1ce";
export var arrowright = "about-gallery-module--arrowright--5081f";
export var imgcontent = "about-gallery-module--imgcontent--fd627";
export var mainholder = "about-gallery-module--mainholder--97f7e";
export var slidejumper = "about-gallery-module--slidejumper--317a0";
export var slidejumpercontent = "about-gallery-module--slidejumpercontent--d77db";
export var slidejumperholder = "about-gallery-module--slidejumperholder--39d03";
export var slidejumpertitle = "about-gallery-module--slidejumpertitle--421be";
export var thumbnail = "about-gallery-module--thumbnail--2f28e";
export var thumbnailactive = "about-gallery-module--thumbnailactive--d5af2";
export var thumbnailimage = "about-gallery-module--thumbnailimage--e69b8";