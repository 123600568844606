import React, { Component } from "react"
import { graphql } from "gatsby" // to query for image data


import {
	CarouselProvider,
	Slide,
	Slider,
	ButtonBack,
	ButtonNext,
	Image
} from "pure-react-carousel"
import 'pure-react-carousel/dist/react-carousel.es.css'


import Layout from "../../components/layout"

import * as styles_slideshow from '../../components/slideshow/style.module.css'
import * as styles from '../../styles/pages/about-gallery.module.css'

import arrowleft from "../../images/home/arrow-left.png"
import arrowright from "../../images/home/arrow-right.png"

import image_arrow_left from "../../images/gallery/arrow-left.png"
import image_arrow_right from "../../images/gallery/arrow-right.png"


import image_nowplaying from "../../images/gallery/nowplaying.png"


export default class AboutGalleryPage extends Component {
	state = {
		width: 1446,
		height: 606,
		slideitemcount: 4,
		curimgidx: 0,
		curimg: null
	}

	componentDidMount() {
		this.handleResize()
		window.addEventListener('resize', this.handleResize)
		this.setImage(0);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
	}

	handleResize = () => {
		var newitemcount = 4;
		if (window.innerWidth<= window.innerHeight) {
			newitemcount = 3;
		}
		this.setState(state => ({
			width: window.innerWidth,
			height: window.innerHeight,
			slideitemcount: newitemcount
		}));
	};

	setImage = (imgidx) => {
		this.setState(state => ({
			curimgidx: imgidx,
			curimg: this.props.data.gallery.edges[imgidx].node.fluid.originalImg
		}));
	};

	handleLeftClick = () => {
		if (this.state.curimgidx > 0) {
			this.setImage(this.state.curimgidx-1);
		} else {
			this.setImage(this.props.data.gallery.edges.length-1);
		}
	}
	handleRightClick = () => {
		if (this.state.curimgidx < this.props.data.gallery.edges.length-1) {
			this.setImage(this.state.curimgidx+1);
		} else {
			this.setImage(0);
		}
	}

	handleWallClick = ({slideindex}) => {
		this.setImage(slideindex);
	}
	handleFloorClick = ({slideindex}) => {
		this.setImage(slideindex+this.props.data.walls.edges.length);
	}
	render() {
		const carouselwdhtratio = 512/288
		return (
		  <Layout activePath='about' fullPath='about/gallery' pageTitle="Gallery">
		  	<div className={"bg-cement-tile text-centered "+styles.mainholder}>
			    <h1 class='font-size-large header-title fullblock-mobile-only'>Gallery<br/><br/></h1>
		  		<input type="image" onClick={this.handleLeftClick} className={styles.arrow+" "+styles.arrowleft}  src={image_arrow_left} alt="<" />
	  			<img className={styles.imgcontent} src={this.state.curimg} alt=""/>
		  		<input type="image" onClick={this.handleRightClick} className={styles.arrow+" "+styles.arrowright}  src={image_arrow_right} alt=">" />
		  	</div>
		  	<div className={styles.slidejumpertitle}>
		  		<span></span>
				<div className="font-size-medium">&nbsp;Walls and Ceilings&nbsp;</div>
			</div>
		  	<div className={styles.slidejumperholder+ " " + (this.state.width > this.state.height?styles_slideshow.slidewide:styles_slideshow.slidetall)}>
		  		<CarouselProvider
					naturalSlideWidth={carouselwdhtratio}
					naturalSlideHeight={1}
					totalSlides={this.props.data.walls.edges.length}
					isPlaying={false}
					hasMasterSpinner={true}
					className={styles_slideshow.container+ " "+styles.slidejumpercontent}
					infinite={true}
					visibleSlides={this.state.slideitemcount}
				>
					<Slider className={styles.slidejumper}>
					{
						this.props.data.walls.edges.map((imgdata, slideindex)=> {
							return (
								<Slide index={slideindex} className={styles.thumbnail+" "+styles["thumbnail"+slideindex]}>
									<Image type="image" className={styles.thumbnailimage} src={imgdata.node.fluid.originalImg} alt="" onClick={()=>{
											this.handleWallClick({slideindex})
										}}  />
									{this.state.curimgidx===slideindex && <img src={image_nowplaying} alt="" className={styles.thumbnailactive} />}
								</Slide>
							)
						})
					}
					</Slider>
					<ButtonBack
						className={`${styles_slideshow.buttonBack} ${styles_slideshow.button}`}
						onClick={this.disableAnimation}
					>
						<img src={arrowleft} alt='<'/>
					</ButtonBack>
					<ButtonNext
						className={`${styles_slideshow.buttonNext} ${styles_slideshow.button}`}
						onClick={this.disableAnimation}
					>
						<img src={arrowright} alt='>'/>
					</ButtonNext>
				</CarouselProvider>
		  	</div>
		  	<div className={styles.slidejumpertitle}>
		  		<span></span>
				<div className="font-size-medium">&nbsp;Flooring&nbsp;</div>
			</div>
		  	<div className={styles.slidejumperholder+ " " + (this.state.width > this.state.height?styles_slideshow.slidewide:styles_slideshow.slidetall)}>
		  		<CarouselProvider
					naturalSlideWidth={carouselwdhtratio}
					naturalSlideHeight={1}
					totalSlides={this.props.data.flooring.edges.length}
					isPlaying={false}
					hasMasterSpinner={true}
					className={styles_slideshow.container+ " "+styles.slidejumpercontent}
					infinite={true}
					visibleSlides={this.state.slideitemcount}
				>
					<Slider className={styles.slidejumper}>
					{
						this.props.data.flooring.edges.map((imgdata, slideindex)=> {
							return (
								<Slide index={slideindex} className={styles.thumbnail+" "+styles["thumbnail"+slideindex]}>
									<Image type="image" className={styles.thumbnailimage} src={imgdata.node.fluid.originalImg} alt="" onClick={()=>{
											this.handleFloorClick({slideindex})
										}}  />
									{this.state.curimgidx===(this.props.data.walls.edges.length+slideindex) && <img src={image_nowplaying} alt="" className={styles.thumbnailactive} />}
								</Slide>
							)
						})
					}
					</Slider>
					<ButtonBack
						className={`${styles_slideshow.buttonBack} ${styles_slideshow.button}`}
						onClick={this.disableAnimation}
					>
						<img src={arrowleft} alt='<'/>
					</ButtonBack>
					<ButtonNext
						className={`${styles_slideshow.buttonNext} ${styles_slideshow.button}`}
						onClick={this.disableAnimation}
					>
						<img src={arrowright} alt='>'/>
					</ButtonNext>
				</CarouselProvider>
		  	</div>
		  	<br/><br/>
		  </Layout>
		)
	}
}



export const data = graphql`
	query galleryPhotos {
		flooring: allImageSharp(
			filter: {fluid: {originalName: {glob: "02_flooring_*"}}}
			sort: { fields: [ fixed___originalName], order: ASC}
		){
			edges {
				node {
					fluid {
						originalImg
						originalName
						aspectRatio
					}
					id
				}
			}
		}
		walls: allImageSharp(
			filter: {fluid: {originalName: {glob: "01_walls_*"}}}
			sort: { fields: [ fixed___originalName], order: ASC}
		){
			edges {
				node {
					fluid {
						originalImg
						originalName
						aspectRatio
					}
					id
				}
			}
		}
		gallery: allImageSharp(
			filter: {fluid: {originalName: {glob: "gallery_*"}}}
			sort: { fields: [ fixed___originalName], order: ASC}
		){
			edges {
				node {
					fluid {
						originalImg
						originalName
						aspectRatio
					}
					id
				}
			}
		}
	}
`